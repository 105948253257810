<template>
    <div class="ml-4">
        <div v-if="editMode=='read'">
            <span v-if="isRanked"># {{index+1}} - </span>
            {{this.originalTitle}}
            <div v-if="item.added_by" class="mt-1">
                <h5> added by <a :href="domain + '/' + item.added_by"> {{item.added_by}} </a> </h5>
            </div>
        </div>
        <div v-if="editMode=='write'">
            <v-text-field v-model="editRanking" label="Ranking" />
            <v-text-field v-model="editTitle" label="Text" ref="itemTextRef" />
        </div>
    </div>
</template>
<script>
import api from '/src/utils/api';

export default {
    data() {
        return {
            domain: window.location.origin,
            editRanking: this.index+1,
            editTitle: this.item.title,
            originalRanking: this.index+1,
            originalTitle: this.item.title,
        };
    },
    watch: {
        editMode(newValue, oldValue) {
            if(newValue == "write") {
                // Refresh editRanking in case the item was dragged around prior to editing this record.
                this.editRanking = this.index+1,

                // Highlight text region so user can start typing as soon as they enter editing mode.
                this.$nextTick(() => {
                    this.$refs.itemTextRef.focus();
                });
            }
        }
    },
    methods: {
        async resetContent() {
            this.editTitle = this.originalTitle;
            this.editRanking = this.originalRanking;
        },
        // getEditedContent() {
        //     return this.editContent;
        // },
        async save() {
                let payload = {
                    listItemId: this.item.listItemId,
                    title: this.editTitle,
                    ranking: this.editRanking
                }
                return api.postAuthOptional('update-list-item', payload).then(() => {
                    this.originalTitle = this.editTitle;

                    // Reload from API to ensure UI is representing same order as database.
                    if (this.editRanking != this.originalRanking)
                        this.refreshList(this.editRanking);
                });
        },
        setContent(content) {
            this.editContent = content;
        }
    },
    props: ['cancelEdit','editMode','item','index','refreshList','saveModalContent','isRanked'],
};
</script>
<style scoped>
</style>